<template>
  <v-text-field
  v-model="inputVal"
    type="text"
    :label='lable'
    key="name"
    :rules="rules"
    :disabled="isDisable"
  ></v-text-field>
</template>

<script>
export default {
   props: ["value","lable","isDisable"],
    data: () => ({
    name: null,
     rules : [v => !!v || 'Town is required'],
   
  }),
   computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>