<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
  <!-- EOC -->
  <!-- BOC:[error] -->
  <AError :api="api"></AError>
  <!-- EOC -->
   <!-- BOC:[form] -->
   <PagePromote
   v-if="me && me.name"
    :url="`${this.$api.servers.sso}/api/v1/en/moderator/migrate/classroom`"
    :self="me"
    :callbackSuccess="callbackSuccess"
  ></PagePromote>
  <!-- EOC -->
</v-container>
</template>

<script>
  //BOC:[model]
  import modelParent from '@/models/items/institution'
  import model from '@/models/items/institutionClassroom'
  //EOC
  import { mapState } from 'vuex'
import PagePromote from '../../components/Moderator/Promote/Classroom/PagePromote.vue'
  export default {
    components:{
    PagePromote
},
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
      'parent',
      'self',
    ],
    data: () => ({
      //BOC:[role]
      role:'Moderator',
      //EOC
      //BOC:[model]
      modelParent: modelParent,
      model: model,
      //EOC
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[api]
      api:{
        isLoading:false,
        isError:false,
        error:null,
        url:null,
      },
      //EOC
      //BOC:[parent]
      myParent:{
        id:null,
      },
      //EOC
      //BOC:[self]
      me:{
        id:null,
      },
      //EOC
      //BOC:[form]
      form: {},
      //EOC
    }),
    created() {
      //BOC:[guard]
      if(!this.model[this.role].delete) {
        this.$router.replace(this.$_getRouteRead(this.role,this.model.key,this.$route.params.id,this.self))
        return
      }
      //EOC
      //BOC:[parent]
      if(this.parent) this.myParent = this.$_.cloneDeep(this.parent)
      //EOC
      //BOC:[self]
      if(this.self) this.me = this.$_.cloneDeep(this.self)
      //EOC
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelParent.name.plural.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.modelParent.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.myParent && this.myParent.name) ? this.myParent.name : `${this.$t("model.name." + this.modelParent.name.singular.toLowerCase())} ${this.$route.params.parentId}`,
        to:this.$_getRouteRead(this.role,this.modelParent.key,this.$route.params.parentId,this.myParent),
        exact:true,
      })
      //
      let path = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
      path.query = {tab:'Classroom'}
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.model.name.plural.toLowerCase()),
        to:path,
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.me.name) ? this.me.name : `${this.$t("model.name." + this.model.name.singular.toLowerCase())} ${this.$route.params.id}`,
        to:this.$_getRouteChildRead(this.role,this.model.key,this.$route.params.id,this.self,this.modelParent.key,this.$route.params.parentId,this.myParent),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:'Promote',
        to:{name:'PageModeratorInstitutionClassroomPromote'},
        exact:false,
      })
      //EOC
      this.api.method = 'GET'
      //BOC:[url]
      this.api.url = `${this.$api.servers.sso}/api/v1/en/moderator/institution/${this.$route.params.parentId}/classroom/${this.$route.params.id}`
      
      //EOC
      //BOC:[api]
      this.api.callbackReset = () => {
        this.api.isLoading = true
        this.api.isError = false
        this.api.error = null
      }
      this.api.callbackError = (e) => {
        this.api.isLoading = false
        this.api.isError = true
        this.api.error = e
      }
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false
       this.me= resp
      }
      //EOC
      //BOC:[form]
      this.form.id = this.$route.params.id
      //EOC
    },
    mounted() {
      if(this.me && !this.me.name){
        this.$api.fetch(this.api)
      }
    },
    methods: {
      callbackSuccess() {
        let redirect = this.$_getRouteRead(this.role,this.modelParent.key,this.$route.params.parentId,this.parent)
        redirect.query = {tab:'Classroom'}
        this.$router.push(redirect)
      },
    }
  }
</script>